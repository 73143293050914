import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import { DonationList } from '../Donations';
import Avatar from '../Avatar';
import CurrencyFormat from 'react-currency-format';

class MemberItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      member: null,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.member) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .member(this.props.match.params.id)
      .onSnapshot(snapshot => {
        this.setState({
          member:  {
            ...snapshot.data(),
            uid: this.props.match.params.id
          },
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { member, loading } = this.state;

    return (
      <article>
        {loading && <div>Loading ...</div>}

        {member && (
          <div className="memberItem">
            <div className="avatar-item">
              <Avatar name={member.name} image={member.avatar} />
            </div>
            <h1>{member.name}</h1>
            <p>Raised: <CurrencyFormat value={member.totalDonations || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></p>

            <DonationList teamId={member.teamId} memberId={member.uid} />
          </div>
        )}
      </article>
    );
  }
}

export default withFirebase(MemberItem);
