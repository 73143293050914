import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import { MemberList } from '../Members';

class TeamItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      team: null,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.team) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .team(this.props.match.params.id)
      .onSnapshot(snapshot => {
        console.log(snapshot.data())
        this.setState({
          team: {
            ...snapshot.data(),
            uid: this.props.match.params.id
          },
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { team, loading } = this.state;

    return (
      <article>
        {loading && <div>Loading ...</div>}
        {team && <>
          <h1>{team.name}</h1>
          {team.avatar && <img src={team.avatar} alt={team.name} />}
          <MemberList teamUid={team.uid} totalDonations={team.totalDonations} />
        </>}
      </article>
    );
  }
}

export default withFirebase(TeamItem);
