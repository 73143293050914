const donationConverter = {
  toFirestore: (donation) => {
    return donation;
  },
  fromFirestore: (snapshot, options) => {
    const { amount, donor, userId, createdAt, memberRef, teamRef } = snapshot.data(options);
    
    return {
      amount,
      donor,
      userId,
      createdAt,
      getMemberName: async () => memberRef.get().then(snapshot => snapshot.data().name),
      getTeamName: async () => teamRef.get().then(snapshot => snapshot.data().name)
    }
  }
};

export default donationConverter;