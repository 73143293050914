import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import CurrencyFormat from 'react-currency-format';
import Avatar from '../Avatar';

class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.team.name,
      members: []
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase
      .members().where('teamId', '==', this.props.team.uid)
      .orderBy('name', 'asc')
      .onSnapshot(snapshot => {
        let members = [];

        snapshot.forEach(doc => {
          const docData = doc.data();
          members.push({
            uid: doc.id,
            name: docData.name,
            avatar: docData.avatar,
            userId: docData.userId,
            teamId: docData.teamId,
            totalDonations: docData.totalDonations,
            createdAt: docData.createdAt
          })
        });

        this.setState({
          members,
        });
      });

  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      editText: this.props.team.name,
    }));
  };

  onChangeEditText = event => {
    this.setState({ editText: event.target.value });
  };

  onSaveEditText = () => {
    this.props.onEditTeam(this.props.team, this.state.editText);

    this.setState({ editMode: false });
  };

  render() {
    const { authUser, team, onRemoveTeam } = this.props;
    const { editMode, editText, members } = this.state;

    return (
      <section className="team">
        {editMode ? (
          <input
            type="text"
            value={editText}
            onChange={this.onChangeEditText}
          />
        ) : (
          <Link
            to={{
              pathname: `${ROUTES.TEAM}/${team.uid}`,
              state: { team },
            }}
          >
            {team.avatar && <img src={team.avatar} alt={team.name} />}
            <h1>{team.name}</h1>
          </Link>
        )}

        <ul className="teamMembers">
          {members.map(member => (
            <li key={member.uid} className="teamMember">
                <Link
                  to={{
                    pathname: `${ROUTES.TEAM}/${this.props.team.uid}/members/${member.uid}`,
                    state: { member },
                  }}
                >
                  <Avatar name={member.name} image={member.avatar} />
                  <h3>{member.name}</h3>
                  <p>Raised: <CurrencyFormat value={member.totalDonations || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></p>
                </Link>
            </li>
          ))}
        </ul>

        <p>
          Total Raised: <CurrencyFormat value={team.totalDonations || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
        </p>

        {authUser && authUser.uid === team.userId && (
          <span>
            {editMode ? (
              <span>
                <button onClick={this.onSaveEditText}>Save</button>
                <button onClick={this.onToggleEditMode}>Reset</button>
              </span>
            ) : (
              <button onClick={this.onToggleEditMode}>Edit</button>
            )}

            {!editMode && (
              <button
                type="button"
                onClick={() => {
                  if (window.confirm("Are you sure you want to delete this team?")) {
                    onRemoveTeam(team.uid)
                  }
                }}
              >
                Delete
              </button>
            )}
          </span>
        )}
      </section>
    );
  }
}

export default withFirebase(Team);