import React from 'react';
import Teams from '../Teams';

const Landing = () => (
  <main>
    <Teams />
  </main>
);

export default Landing;
