import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import TeamList from './TeamList';
import { DonationList } from '../Donations';
import FileUploader from 'react-firebase-file-uploader';

class Teams extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      isUploading: false,
      avatar: '',
      loading: false,
      teams: [],
      limit: 20,
      hasNextPage: false
    };
  }

  componentDidMount() {
    this.onListenForTeams();
  }

  onListenForTeams = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .teams()
      .orderBy('createdAt', 'asc')
      .limit(this.state.limit)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let teams = [];
          snapshot.forEach(doc =>
            teams.push({ ...doc.data(), uid: doc.id }),
          );

          let hasNextPage = snapshot.size === this.state.limit;

          this.setState({
            teams: teams,
            loading: false,
            hasNextPage
          });
        } else {
          this.setState({ teams: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.unsubscribe();
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onCreateTeam = (event, authUser) => {
    this.props.firebase.teams().add({
      name: this.state.text,
      avatar: this.state.avatar,
      userId: authUser.uid,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditTeam = (team, name) => {
    const { uid, ...teamSnapshot } = team;

    this.props.firebase.team(team.uid).update({
      ...teamSnapshot,
      name,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onRemoveTeam = uid => {
    this.props.firebase.team(uid).delete();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 20 }),
      this.onListenForTeams,
    );
  };

  onUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  onUploadProgress = progress => this.setState({ progress });
  onUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };
  onUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    this.props.firebase
      .storage
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ avatar: url }));
  };

  render() {
    const { text, teams, loading, hasNextPage } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <article>
            {teams && (
              <TeamList
                authUser={authUser}
                teams={teams}
                onEditTeam={this.onEditTeam}
                onRemoveTeam={this.onRemoveTeam}
              />
            )}

            {!teams && <div>There are no teams ...</div>}

            {!loading && teams && hasNextPage && (
              <button type="button" onClick={this.onNextPage}>
                More
              </button>
            )}

            {loading && <div>Loading ...</div>}


            {authUser && (
              <form
                onSubmit={event =>
                  this.onCreateTeam(event, authUser)
                }
              >
                <input
                  type="text"
                  value={text}
                  onChange={this.onChangeText}
                />

                <label>Avatar:</label>
                {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                {this.state.avatar && <img src={this.state.avatar} alt={text} />}
                <FileUploader
                  accept="image/*"
                  name="avatar"
                  randomizeFilename
                  storageRef={this.props.firebase.storage.ref("images")}
                  onUploadStart={this.onUploadStart}
                  onUploadError={this.onUploadError}
                  onUploadSuccess={this.onUploadSuccess}
                  onProgress={this.onUploadProgress}
                />

                <button type="submit">Send</button>
              </form>
            )}

            <DonationList title="Recent" />
          </article>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Teams);
