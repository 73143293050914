import React, { Component } from 'react';

import { AuthUserContext } from '../Session';

import { withFirebase } from '../Firebase';
import CurrencyFormat from 'react-currency-format';

class DonationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      donor: '',
      amount: '',
      loading: false,
      donations: [],
      editMode: false,
      memberId: props.memberId
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    if (this.props.memberId) {
      this.unsubscribe = this.props.firebase
        .donations()
        .where('memberId', '==', this.props.memberId)
        .onSnapshot(snapshot => {
          let donations = [];

          snapshot.forEach(doc => {
            const docData = doc.data();
            return donations.push({
              amount: docData.amount,
              donor: docData.donor,
              userId: docData.userId,
              createdAt: docData.createdAt,
              uid: doc.id
            })
          });

          donations.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)

          this.setState({
            donations,
            loading: false,
          });
        });
    } else if (this.props.teamId) {
      this.unsubscribe = this.props.firebase
        .donations().where('teamId', '==', this.props.teamId)
        .onSnapshot(async snapshot => {
          let donations = [];

          let donationPromises = [];

          for (const doc of snapshot.docs) {
            donationPromises.push(
              new Promise(async (resolve, reject) => {
                const docData = doc.data()
                const memberName = await docData.getMemberName()

                donations.push({
                  ...docData,
                  memberName,
                  uid: doc.id
                })
                resolve();
              })
            )
          };

          Promise.all(donationPromises).then(() => {
            donations.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
            this.setState({
              donations,
              loading: false,
            });
          })
        });
    } else {
      this.unsubscribe = this.props.firebase
        .donations()
        .orderBy('createdAt', 'desc')
        .limit(20)
        .onSnapshot(async snapshot => {
          let donations = [];

          let donationPromises = [];

          for (const doc of snapshot.docs) {
            donationPromises.push(
              new Promise(async (resolve, reject) => {
                const docData = doc.data()
                Promise.all([
                  docData.getMemberName().then((memberName) => {docData.memberName = memberName}), 
                  docData.getTeamName().then((teamName) => {docData.teamName = teamName})
                ]).then(() => {
                  donations.push({
                    ...docData,
                    uid: doc.id
                  })
                  resolve();
                })
              })
            )
          };

          Promise.all(donationPromises).then(() => {
            donations.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
            this.setState({
              donations,
              loading: false,
            });
          })
        });
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onChangeDonor = event => {
    this.setState({ donor: event.target.value });
  };

  onChangeAmount = amount => {
    this.setState({ amount });
  };

  onChangeMember = event => {
    this.setState({ memberId: event.target.value });
  };

  onCreateDonation = (event, authUser) => {
    this.props.firebase.donations().add({
      donor: this.state.donor,
      amount: this.state.amount,
      userId: authUser.uid,
      teamId: this.props.teamId,
      teamRef: this.props.firebase.team(this.props.teamId),
      memberId: this.state.memberId || '',
      memberRef: this.state.memberId ? this.props.firebase.member(this.state.memberId) : '',
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
    });

    this.setState({ donor: '', amount: '' });

    event.preventDefault();
  };

  onEditDonation = (donation, donor, amount) => {
    const { uid, ...donationSnapshot } = donation;

    this.props.firebase.donation(uid).update({
      ...donationSnapshot,
      donor,
      amount,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onRemoveDonation = uid => {
    this.props.firebase.donation(uid).delete();
  };

  render() {
    const { donations, loading, donor, amount, memberId, editMode } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            {loading && <div>Loading ...</div>}
            {donations.length > 0 && (
              <>
                <h2>{this.props.title} Donations</h2>
                <ul className="donations">
                  {donations.map(donation => (
                    <li key={donation.uid} className="donation">
                      <p className="money"><CurrencyFormat value={donation.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></p>
                      <h4 className="donor">{donation.donor || 'Anonymous'}</h4>
                      {donation.createdAt && <h5 className="donationDate">{donation.createdAt.toDate().toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}</h5>}
                      {donation.teamName && <h5 className="donationTeam">{donation.teamName}</h5>}
                      {donation.memberName && <h5 className="donationMember">{donation.memberName}</h5>}

                      {authUser && authUser.uid === donation.userId && (
                        <span>
                          {!editMode && (
                            <button
                              type="button"
                              onClick={() => {
                                if (window.confirm("Are you sure you want to delete this donation?")) {
                                  this.onRemoveDonation(donation.uid)
                                }
                              }}
                            >
                              Delete
                            </button>
                          )}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </>
            )}

            {authUser && this.props.teamId && (
              <form
                onSubmit={event =>
                  this.onCreateDonation(event, authUser)
                }
              >
                <h4>Add a donation</h4>
                <fieldset>
                  <label htmlFor="amount">Amount: </label>
                  <CurrencyFormat value={amount} thousandSeparator={true} id="amount" prefix={'$'} type="tel" onValueChange={(values) => {
                    const { value } = values;
                    this.onChangeAmount(value);
                  }} />
                </fieldset>
                <fieldset>
                  <label htmlFor="donor">Donor: </label>
                  <input
                    id="donor"
                    type="text"
                    value={donor}
                    onChange={this.onChangeDonor}
                  />
                </fieldset>
                {!this.props.memberId && (
                  <fieldset>
                    <label htmlFor="fundraiser">Fundraiser: </label>

                    <select onChange={this.onChangeMember} id="fundraiser" value={memberId}>
                      <option value=""></option>
                      {this.props.members.map(member => <option key={member.uid} value={member.uid}>{member.name}</option>)}
                    </select>
                  </fieldset>
                )}

                <button type="submit">Send</button>
              </form>
            )}
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(DonationList);
