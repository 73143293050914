export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const TEAM = '/teams';
export const TEAM_DETAILS = '/teams/:id';
export const MEMBER_DETAILS = '/teams/:id/members/:id';
