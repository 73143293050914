import React from 'react';

import Team from './Team';

const TeamList = ({
  authUser,
  teams,
  onEditTeam,
  onRemoveTeam,
}) => (
  <section>
    {teams.map(team => (
      <Team
        authUser={authUser}
        key={team.uid}
        team={team}
        onEditTeam={onEditTeam}
        onRemoveTeam={onRemoveTeam}
      />
    ))}
  </section>
);

export default TeamList;
