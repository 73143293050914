import React from 'react';
import Color from 'color';

const colorMap = {
  a: '#ffc0cb',
  b: '#ffd5c0',
  c: '#c0ebff',
  d: '#c0ffd5',
  e: '#d5c0ff',
  f: '#fff5c0',
  g: '#ff8da1',
  h: '#ffc0cb',
  i: '#ffd5c0',
  j: '#c0ebff',
  k: '#c0ffd5',
  l: '#d5c0ff',
  m: '#fff5c0',
  n: '#ff8da1',
  o: '#ffc0cb',
  p: '#ffd5c0',
  q: '#c0ebff',
  r: '#c0ffd5',
  s: '#d5c0ff',
  t: '#fff5c0',
  u: '#ff8da1',
  v: '#ffc0cb',
  x: '#ffd5c0',
  y: '#c0ebff',
  z: '#c0ffd5',
}

const Avatar = (props) => {
  let userColor = Color(colorMap.a);
  const lastInitial = props.name.match(/\b[\w\d]/g).slice(-1)[0];
  const firstInitial = props.name.match(/\b[\w\d]/g)[0];

  if (props.name) {
    if (lastInitial && colorMap[lastInitial.toLowerCase()]) {
      userColor = Color(colorMap[lastInitial.toLowerCase()])
    }
  }

  return (
    <figure className="avatar" style={
      { 
        '--color-1': userColor.rgb().string(),
        '--color-2': userColor.darken(0.20).rgb().string(),
      }
      }>
      {props.image ? <img src={props.image} alt={props.name} /> : <span className="avatar-label">{firstInitial}{lastInitial}</span>}
    </figure>
  )
};

export default Avatar;