import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import { DonationList } from '../Donations';
import * as ROUTES from '../../constants/routes';
import FileUploader from 'react-firebase-file-uploader';
import CurrencyFormat from 'react-currency-format';
import Avatar from '../Avatar';

class MemberList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      amount: '',
      isUploading: false,
      avatar: '',
      loading: false,
      members: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .members().where('teamId', '==', this.props.teamUid)
      .orderBy('name', 'asc')
      .onSnapshot(snapshot => {
        let members = [];

        snapshot.forEach(doc => {
          const docData = doc.data();
          members.push({
            uid: doc.id,
            name: docData.name,
            avatar: docData.avatar,
            userId: docData.userId,
            teamId: docData.teamId,
            totalDonations: docData.totalDonations,
            createdAt: docData.createdAt
          })
        });

        this.setState({
          members,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };

  onCreateMember = (event, authUser) => {
    this.props.firebase.members().add({
      name: this.state.text,
      avatar: this.state.avatar,
      userId: authUser.uid,
      teamId: this.props.teamUid,
      teamRef: this.props.firebase.team(this.props.teamUid),
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
    });

    this.setState({ text: '' });

    event.preventDefault();
  };

  onEditMember = (member, name) => {
    const { uid, ...memberSnapshot } = member;

    this.props.firebase.member(uid).update({
      ...memberSnapshot,
      name,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onRemoveMember = uid => {
    this.props.firebase.member(uid).delete();
  };

  render() {
    const { members, loading, text, avatar } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <p>
              Total Raised: <CurrencyFormat value={this.props.totalDonations || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </p>
            <h2>Members</h2>
            {loading && <div>Loading ...</div>}
            <ul className="teamMembers">
              {members.map(member => (
                <li key={member.uid} className="teamMember">
                    <Link
                      to={{
                        pathname: `${ROUTES.TEAM}/${this.props.teamUid}/members/${member.uid}`,
                        state: { member },
                      }}
                    >
                      <Avatar name={member.name} image={member.avatar} />
                      <h3>{member.name}</h3>
                      <p>Raised: <CurrencyFormat value={member.totalDonations || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></p>
                    </Link>
                </li>
              ))}
            </ul>

            {authUser && (
              <form
                onSubmit={event =>
                  this.onCreateMember(event, authUser)
                }
              >
                <h4>Add a member</h4>
                <fieldset>
                  <label htmlFor="member">Member Name:</label>
                  <input
                    id="member"
                    type="text"
                    value={text}
                    onChange={this.onChangeText}
                  />
                </fieldset>
                <fieldset>
                <label>Avatar:</label>
                  {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
                  {avatar && <img src={avatar} alt={text} />}
                  <FileUploader
                    accept="image/*"
                    name="avatar"
                    randomizeFilename
                    storageRef={this.props.firebase.storage.ref("images")}
                    onUploadStart={this.onUploadStart}
                    onUploadError={this.onUploadError}
                    onUploadSuccess={this.onUploadSuccess}
                    onProgress={this.onUploadProgress}
                  />
                </fieldset>

                <button type="submit">Send</button>
              </form>
            )}
            <DonationList teamId={this.props.teamUid} members={this.state.members.map(({uid, name}) => {
              return {uid, name}
            })} />
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(MemberList);
