import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Teams from './Teams';
import TeamItem from './TeamItem';
import MemberItem from '../Members/MemberItem';

import * as ROUTES from '../../constants/routes';

const TeamPage = () => (
  <Switch>
    <Route exact path={ROUTES.TEAM_DETAILS} component={TeamItem} />
    <Route exact path={ROUTES.MEMBER_DETAILS} component={MemberItem} />
    <Route exact path={ROUTES.LANDING} component={Teams} />
  </Switch>
);

export default TeamPage;