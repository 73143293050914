const memberConverter = {
  toFirestore: (donation) => {
    return donation;
  },
  fromFirestore: (snapshot, options) => {
    const { name, avatar, userId, createdAt, teamId, totalDonations } = snapshot.data(options);
    return { name, avatar, userId, createdAt, teamId, totalDonations }
  }
};

export default memberConverter;