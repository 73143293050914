import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

class DonationItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      donation: null,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.donation) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .donation(this.props.match.params.id)
      .onSnapshot(snapshot => {
        this.setState({
          donation: snapshot.data(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { donation, loading } = this.state;

    return (
      <article>
        {loading && <div>Loading ...</div>}

        {donation && (
          <div>
            <h1>{donation.name}</h1>
          </div>
        )}
      </article>
    );
  }
}

export default withFirebase(DonationItem);
